/* 
followed this: https://tiptap.dev/docs/examples/basics/formatting 
adapted css found in sources of above link for button highlighting for selection 
changes to original css have original css directly above them
*/

/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul, 
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1, 
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 { 
    font-size: 1.4rem; 
  }

  h2 { 
    font-size: 1.2rem; 
  }

  h3 { 
    font-size: 1.1rem; 
  }

  h4, 
  h5, 
  h6 { 
    font-size: 1rem; 
  }

  /* Code and preformatted text styles */
  code {
    /* background-color: var(--purple-light); */
    background-color: var(--blue-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  mark {
    background-color: #FAF594;
    border-radius: 0.4rem;
    box-decoration-break: clone;
    padding: 0.1rem 0.3rem;
  }

  blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }

  /* copied from here https://embed.tiptap.dev/assets/helper-Bzo17db4.css */
  *,*:before,*:after {
    box-sizing: border-box
  }

  html {
    font-family: Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
    line-height: 1.5;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased
  }

  body {
    min-height: 25rem;
    margin: 0
  }


  .tiptap {
    /* caret-color: var(--purple); */
    caret-color: var(--blue);
    /* margin: 1.5rem */
    margin-top: 1.5rem;
  }

  .tiptap:focus {
    /* outline: none */
  }

  ::-webkit-scrollbar {
    height: 14px;
    width: 14px
  }

  ::-webkit-scrollbar-track {
    background-clip: padding-box;
    background-color: transparent;
    border: 4px solid transparent;
    border-radius: 8px
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: #0000;
    border: 4px solid rgba(0,0,0,0);
    border-radius: 8px
  }

  :hover::-webkit-scrollbar-thumb {
    background-color: #0000001a
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #00000026
  }

  ::-webkit-scrollbar-button {
    display: none;
    height: 0;
    width: 0
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent
  }

  button,input,select,textarea {
    background: var(--gray-2);
    border-radius: .5rem;
    border: none;
    color: var(--black);
    font-family: inherit;
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.15;
    margin: none;
    padding: .375rem .625rem;
    transition: all .2s cubic-bezier(.65,.05,.36,1)
  }

  button:hover,input:hover,select:hover,textarea:hover {
    background-color: var(--gray-3);
    color: var(--black-contrast)
  }

  button[disabled],input[disabled],select[disabled],textarea[disabled] {
    background: var(--gray-1);
    color: var(--gray-4)
  }

  button:checked,input:checked,select:checked,textarea:checked {
    accent-color: var(--purple)
  }

  button.primary,input.primary,select.primary,textarea.primary {
    background: var(--black);
    color: var(--white)
  }

  button.primary:hover,input.primary:hover,select.primary:hover,textarea.primary:hover {
    background-color: var(--black-contrast)
  }

  button.primary[disabled],input.primary[disabled],select.primary[disabled],textarea.primary[disabled] {
    background: var(--gray-1);
    color: var(--gray-4)
  }

  button.is-active,input.is-active,select.is-active,textarea.is-active {
    /* background: var(--purple); */
    background: var(--blue);
    color: var(--white)
  }

  button.is-active:hover,input.is-active:hover,select.is-active:hover,textarea.is-active:hover {
    /* background-color: var(--purple-contrast); */
    background-color: var(--blue-contrast);
    color: var(--white)
  }

  button.saved.is-active {
    visibility: visible;
    background-color: var(--green);
    color: var(--white);
  }
  
  button.saved {
    visibility: hidden;
  }

  button:not([disabled]),select:not([disabled]) {
    cursor: pointer
  }

  input[type=text],textarea {
    background-color: unset;
    border: 1px solid var(--gray-3);
    border-radius: .5rem;
    color: var(--black)
  }

  input[type=text]::-moz-placeholder,textarea::-moz-placeholder {
    color: var(--gray-4)
  }

  input[type=text]::placeholder,textarea::placeholder {
    color: var(--gray-4)
  }

  input[type=text]:hover,textarea:hover {
    background-color: unset;
    border-color: var(--gray-4)
  }

  input[type=text]:focus-visible,input[type=text]:focus,textarea:focus-visible,textarea:focus {
    /* border-color: var(--purple); */
    border-color: var(--blue);
    outline: none
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="Gray" d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right .1rem center;
    background-size: 1.25rem 1.25rem;
    padding-right: 1.25rem
  }

  select:focus {
    outline: 0
  }

  form {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: .25rem
  }

  .hint {
    align-items: center;
    background-color: var(--yellow-light);
    border-radius: .5rem;
    border: 1px solid var(--gray-2);
    display: flex;
    flex-direction: row;
    font-size: .75rem;
    gap: .25rem;
    line-height: 1.15;
    padding: .3rem .5rem
  }

  .hint.purple-spinner,.hint.error {
    justify-content: center;
    text-align: center;
    width: 100%
  }

  .hint .badge {
    background-color: var(--gray-1);
    border: 1px solid var(--gray-3);
    border-radius: 2rem;
    color: var(--gray-5);
    font-size: .625rem;
    font-weight: 700;
    line-height: 1;
    padding: .25rem .5rem
  }

  .hint.purple-spinner {
    background-color: var(--purple-light)
  }

  .hint.purple-spinner:after {
    content: "";
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='30px' viewBox='0 0 24 30' style='enable-background:new 0 0 50 50;' xml:space='preserve'><rect x='0' y='10' width='6' height='10' fill='%236A00F5' rx='3' ry='3'><animateTransform attributeType='xml' attributeName='transform' type='translate' values='0 0; 0 5; 0 -5; 0 0' begin='0' dur='0.6s' repeatCount='indefinite'/></rect><rect x='10' y='10' width='6' height='10' fill='%236A00F5' rx='3' ry='3'><animateTransform attributeType='xml' attributeName='transform' type='translate' values='0 0; 0 5; 0 -5; 0 0' begin='0.2s' dur='0.6s' repeatCount='indefinite'/></rect><rect x='20' y='10' width='6' height='10' fill='%236A00F5' rx='3' ry='3'><animateTransform attributeType='xml' attributeName='transform' type='translate' values='0 0; 0 5; 0 -5; 0 0' begin='0.4s' dur='0.6s' repeatCount='indefinite'/></rect></svg>");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 1rem;
    width: 1rem
  }

  .hint.error {
    background-color: var(--red-light)
  }

  .label,.label-small,.label-large {
    color: var(--black);
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1.15
  }

  .label-small {
    color: var(--gray-5);
    font-size: .75rem;
    font-weight: 400
  }

  .label-large {
    font-size: .875rem;
    font-weight: 700
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-3);
    margin: 0;
    width: 100%
  }

  kbd {
    background-color: var(--gray-2);
    border: 1px solid var(--gray-2);
    border-radius: .25rem;
    font-size: .6rem;
    line-height: 1.15;
    padding: .1rem .25rem;
    text-transform: uppercase
  }

  #app,.container {
    display: flex;
    flex-direction: column
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: .25rem
  }

  .control-group {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding: 1.5rem */
  
  }

  .control-group .sticky {
    position: sticky;
    top: 0
  }

  [data-node-view-wrapper]>.control-group {
    padding: 0
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    width: 100%
  }

  .switch-group {
    align-items: center;
    background: var(--gray-2);
    border-radius: .5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 1 auto;
    justify-content: flex-start;
    padding: .125rem
  }

  .switch-group label {
    align-items: center;
    border-radius: .375rem;
    color: var(--gray-5);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: .75rem;
    font-weight: 500;
    gap: .25rem;
    line-height: 1.15;
    min-height: 1.5rem;
    padding: 0 .375rem;
    transition: all .2s cubic-bezier(.65,.05,.36,1)
  }

  .switch-group label:has(input:checked) {
    background-color: var(--white);
    color: var(--black-contrast)
  }

  .switch-group label:hover {
    color: var(--black)
  }

  .switch-group label input {
    display: none;
    margin: unset
  }

  .output-group {
    background-color: var(--gray-1);
    display: flex;
    flex-direction: column;
    font-family: JetBrainsMono,monospace;
    font-size: .75rem;
    gap: 1rem;
    margin-top: 2.5rem;
    padding: 1.5rem
  }

  .output-group label {
    color: var(--black);
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.15
  }

  /* https://tiptap.dev/docs/editor/extensions/functionality/placeholder#emptyeditorclass */
  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  /* Placeholder (on every new line) */
  /* .is-empty::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  } */
}


:root {
  --white: #FFF;
  --black: #2E2B29;
  --black-contrast: #110F0E;
  --gray-1: rgba(61, 37, 20, .05);
  --gray-2: rgba(61, 37, 20, .08);
  --gray-3: rgba(61, 37, 20, .12);
  --gray-4: rgba(53, 38, 28, .3);
  --gray-5: rgba(28, 25, 23, .6);
  --green: #22C55E;
  --purple: #6A00F5;
  --purple-contrast: #5800CC;
  --purple-light: rgba(88, 5, 255, .05);
  --yellow-contrast: #FACC15;
  --yellow: rgba(250, 204, 21, .4);
  --yellow-light: #FFFAE5;
  --red: #FF5C33;
  --red-light: #FFEBE5;
  --shadow: 0px 12px 33px 0px rgba(0, 0, 0, .06), 0px 3.618px 9.949px 0px rgba(0, 0, 0, .04);
  --blue: #081E2A;
  --blue-contrast: #081E2A;
  --blue-light: #081E2A;
}