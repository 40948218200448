/* from here: https://codesandbox.io/p/sandbox/match-your-brand-vdwk0?file=%2Fsrc%2Fstyles.css%3A1%2C1-41%2C1 */
/* 
refer to this: 
https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
 */
@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

/* amplify styling for now, theme related attempts in App.js were unsuccessful */

.amplify-button {
  color: #081e2a !important;
}
.amplify-button:hover {
  border-color: #081e2a !important;
  /* color: #081e2a !important; */
}
.amplify-button--active {
  border-color: #081e2a !important;
}
.amplify-button--primary {
  background-color: #081e2a !important;
  color: white !important;
}
.amplify-tabs__item {
  color: #081e2a !important;
}
.amplify-tabs__item:hover {
  border-color: #081e2a !important; /*fixed transition issue*/
}
.amplify-tabs__item--active {
  border-color: #081e2a !important;
}

/* fc overrides */
.fc-event-main{
  overflow: hidden;
}
.fc.fc-theme-standard {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
/* TODO remove?? */
/* still needs to be here for now while share trip has old fc implementation I think */
/* custom css for override full calendar toolbar dispaly on mobile */
.fc .fc-toolbar {
  display: flex !important;
}
@media screen and (max-width: 600px) {
  .fc .fc-toolbar {
    display: block !important;
  }
}

/* full calendar styling overrides */
.fc-toolbar h2 {
  display: inline;
  vertical-align: middle;
}

.fc .fc-button-group {
  display: flex;
  justify-content: stretch;
}

.fc-button-primary {
  background-color: #081e2a !important;
}

/* bootstrap overrides, as part of fc */
a {
  color: black;
  text-decoration: none;
}

.custom-bs-popover {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.popover-body {
  img {height: auto;  width: 100%;}
}

/* used in share trip */
.inline-link{
  color: #081E2A !important;
  -webkit-text-decoration: underline !important;
  text-decoration: underline !important;
  text-decoration-color: rgba(8, 30, 42, 0.4) !important;
}
